<template>
  <inline-svg v-if="flagUrl" :src="flagUrl" :width="32" />
</template>

<script setup>
import { ref, watch } from "vue"

import InlineSvg from "vue-inline-svg"

const props = defineProps({
  code: {
    type: String,
    required: true
  }
})

const flagUrl = ref("")

const loadFlag = async (code) => {
  try {
    const flagModule = await import(`@/assets/flags/${code}.svg`)
    flagUrl.value = flagModule.default
  } catch (error) {
    console.error(`Flag for country code ${code} not found`, error)
    flagUrl.value = ""
  }
}

watch(
  () => props.code,
  (newCode) => {
    if (newCode) {
      loadFlag(newCode)
    }
  },
  { immediate: true }
)
</script>
