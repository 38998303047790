import axios from "axios"

import { getModuleForRoute } from "@/plugins/router.js"

export const getModuleConfiguration = () => axios.get(`/api/${getModuleForRoute()}/appSettings/getModuleConfiguration`)

export const getModuleSettings = () => axios.get(`/api/${getModuleForRoute()}/appSettings/getModuleSettings`)

export const setModuleSettings = (settings) => axios.post(`/api/${getModuleForRoute()}/appSettings/setModuleSettings`, { settings })

export const getModulePrivateSettings = () => axios.get(`/api/${getModuleForRoute()}/appSettings/getNonPublicModuleSettings`)

export const setModulePrivateSettings = (settings) => axios.post(`/api/${getModuleForRoute()}/appSettings/setNonPublicModuleSettings`, settings)
