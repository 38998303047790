<template>
  <app-button @click="triggerFileSelect">
    <slot />
    <input ref="fileInput" type="file" :accept="accept" :multiple="multiple" style="display: none" @change="uploadFiles" />
  </app-button>
</template>

<script setup>
import { ref } from "vue"

import * as filesService from "@/services/files.js"
import * as quasarUtils from "@/services/quasarUtils.js"

import i18n from "@/plugins/i18n.js"

const props = defineProps({
  randomizeFileName: Boolean,
  randomizeForBatch: Boolean,
  filePrefix: { type: String },
  fileTypes: { type: Array },
  accept: { type: String },
  maxSize: { type: Number, default: 5000000 },
  rawResult: Boolean,
  rawResultAsText: Boolean,
  multiple: Boolean
})

const emit = defineEmits(["upload", "beforeUpload"])

const fileInput = ref(null)

const triggerFileSelect = () => {
  if (fileInput.value) {
    fileInput.value.click()
  }
}

const createGuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0
    const v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const validateFiles = (files) => {
  const result = { valid: true }
  Array.from(files).forEach((file) => {
    if (file.size > props.maxSize) {
      result.valid = false
      result.code = 3
      result.data = file.size
      return
    }

    if (props.fileTypes && props.fileTypes.length && props.fileTypes[0] !== "*") {
      const ext = filesService.getFileExtension(file.name).toLowerCase()
      if (!props.fileTypes.includes(ext)) {
        result.valid = false
        result.code = 2
        result.data = ext
        return
      }
    }
  })
  return result
}

const uploadFiles = (event) => {
  const files = event.target.files
  if (!files || !files.length) {
    return
  }

  emit("beforeUpload", files)

  const validationResult = validateFiles(files)

  if (!validationResult.valid) {
    if (validationResult.code === 3) {
      quasarUtils.showErrorToast({
        message: i18n.global.t("_RSFAP_SHARED_FILE_UPLOAD_ATTACHMENT_SIZE_EXCEEDED", [validationResult.data, props.maxSize / 1000])
      })
      emit("upload", { resultCode: 3, resultData: validationResult.data })
    }

    if (validationResult.code === 2) {
      quasarUtils.showErrorToast({ message: i18n.global.t("_RSFAP_SHARED_FILE_UPLOAD_INVALID_EXTENSION", [validationResult.data]) })
      emit("upload", { resultCode: 2, resultData: validationResult.data })
    }
    return
  }

  if (props.rawResult && props.multiple) {
    console.error("localFileUploader: raw result is not supported together with multiple upload, rejecting")
    quasarUtils.showErrorToast({ message: i18n.global.t("_RSFAP_SHARED_FILE_UPLOAD_UNSUPPORTED_OPERATION") })
    return
  }

  if (!props.rawResult || props.multiple) {
    if (props.randomizeFileName) {
      const guid = createGuid()
      const renamedFiles = Array.from(files).map((file) => {
        const name = filesService.getFileNameWithoutExtension(file.name)
        const ext = filesService.getFileExtension(file.name)
        const guidPerFile = props.randomizeForBatch ? guid : createGuid()
        let newName = `${name}_${guidPerFile}${ext}`
        if (props.filePrefix) {
          newName = props.filePrefix + newName
        }
        return new File([file], newName, { type: file.type })
      })
      emit("upload", { resultCode: 1, resultData: props.multiple ? renamedFiles : renamedFiles[0] })
      event.target.value = null
    } else {
      emit("upload", { resultCode: 1, resultData: props.multiple ? files : files[0] })
      event.target.value = null
    }
    return
  }

  const reader = new FileReader()
  reader.addEventListener("load", () => {
    emit("upload", { resultCode: 1, resultData: reader.result })
  })
  if (files[0]) {
    if (props.rawResultAsText) {
      reader.readAsText(files[0])
    } else {
      reader.readAsDataURL(files[0])
    }
  }
}
</script>
