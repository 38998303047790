<template>
  <div class="hidden">
    <component ref="components" v-for="(item, index) in items" :key="index" :is="item.component" v-bind="item.inputProps" />
  </div>
</template>

<script setup>
import { ref } from "vue"

const props = defineProps({
  items: Array
})

const components = ref([])

const emit = defineEmits(["invalidate", "validate"])

const validate = async () => {
  let invalidCount = 0
  await Promise.allSettled(
    components.value.map(async (item, index) => {
      try {
        if (!(await item.validate())) {
          emit("invalidate", props.items[index].validatedItem)
          invalidCount++
        } else {
          emit("validate", props.items[index].validatedItem)
        }
      } catch (error) {
        console.error(error)
      }
    })
  )
  return invalidCount === 0
}

defineExpose({ validate })
</script>
