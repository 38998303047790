<template>
  <div>
    <q-tabs v-model="tab" dense>
      <q-tab v-for="tab in topTabs" :name="tab.name" :key="tab.name" :disable="tab.disable" :class="{ 'text-negative': tab.invalid }">
        <div class="row items-center gap-1 text-subtitle2">
          <div>{{ tab.label }}</div>
          <q-icon v-if="tab.invalid" size="xs" name="error" class="text-negative" />
        </div>
      </q-tab>
    </q-tabs>
    <q-tabs v-if="bottomTabs.length" v-model="tab" dense>
      <q-tab v-for="tab in bottomTabs" :name="tab.name" :key="tab.name" :disable="tab.disable" :class="{ 'text-negative': tab.invalid }">
        <div class="row items-center gap-1 text-subtitle2">
          <div>{{ tab.label }}</div>
          <q-icon v-if="tab.invalid" size="xs" name="error" class="text-negative" />
        </div>
      </q-tab>
    </q-tabs>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from "vue"
import { useRouter, useRoute } from "vue-router"

const router = useRouter()
const route = useRoute()

const tab = ref(null)

const props = defineProps({
  modelValue: String,
  tabs: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every((tab) => tab.name && tab.label)
    }
  },
  useRouting: Boolean,
  subTabs: Boolean
})

const emits = defineEmits(["update:modelValue"])

const TOP_TABS_LIMIT = 6
const topTabs = computed(() => props.tabs.slice(0, TOP_TABS_LIMIT))
const bottomTabs = computed(() => props.tabs.slice(TOP_TABS_LIMIT))

onMounted(() => {
  if (props.subTabs) {
    if (route.params.subtab && props.tabs.some((tab) => tab.name === route.params.subtab)) {
      tab.value = route.params.subtab
    }
  } else if (route.params.tab && props.tabs.some((tab) => tab.name === route.params.tab)) {
    tab.value = route.params.tab
  }
})

watch(
  () => props.modelValue,
  (newValue) => {
    tab.value = newValue
    const tabInfo = props.tabs.find((tab) => tab.name === newValue)
    if (props.useRouting) {
      if (!props.subTabs) {
        const keepSubtab = tabInfo.hasSubTabs?.includes(route.params.subtab)
        router.replace({ name: route.name, params: { ...route.params, tab: newValue, subtab: keepSubtab ? route.params.subtab : null } })
      } else {
        router.replace({ name: route.name, params: { ...route.params, subtab: newValue } })
      }
    }
  },
  { immediate: true }
)

watch(
  () => tab.value,
  (newValue) => {
    emits("update:modelValue", newValue)
  }
)
</script>
