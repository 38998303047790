import { ref } from "vue"
import { defineStore } from "pinia"

import * as moduleSettingsApi from "@/api/moduleSettingsApi.js"

export const useModuleDataStore = defineStore("moduleData", () => {
  const moduleConfig = ref({})
  const moduleSettings = ref({})

  const fetchData = async () => {
    const [moduleConfigData, moduleSettingsData] = await Promise.all([moduleSettingsApi.getModuleConfiguration(), moduleSettingsApi.getModuleSettings()])

    moduleConfig.value = moduleConfigData
    moduleSettings.value = moduleSettingsData
  }

  const getModulePrivateSettings = () => moduleSettingsApi.getModulePrivateSettings()

  const setModulePrivateSettings = async (settings) => {
    await moduleSettingsApi.setModulePrivateSettings(settings)
    await getModulePrivateSettings()
  }

  const setModuleSettings = async (settings) => {
    await moduleSettingsApi.setModuleSettings(settings)
    await fetchData()
  }

  return { moduleConfig, moduleSettings, fetchData, setModuleSettings, getModulePrivateSettings, setModulePrivateSettings }
})
