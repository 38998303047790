<template>
  <app-input ref="input" :model-value="internalValue" @blur="onBlur" @update:model-value="handleUpdateModelValue">
    <template v-for="(slot, name) in $slots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope ?? {}" />
    </template>
  </app-input>
</template>

<script setup>
import { ref, watch } from "vue"

import * as coordinatesService from "@/services/coordinates.js"

const props = defineProps({
  modelValue: Number,
  coordinates: {
    type: String,
    required: true,
    validator: (value) => ["latitude", "longitude"].includes(value)
  }
})

const input = ref(null)

const emit = defineEmits(["update:modelValue"])

defineExpose({
  focus: () => input.value.focus()
})

const internalValue = ref(props.modelValue)

const valueToUpdateOnBlur = ref(null)
const internalValueToUpdateOnBlur = ref(null)

const handleUpdateModelValue = (value) => {
  const isLatitude = props.coordinates === "latitude"
  const decimalValue = coordinatesService.convertCoordinatesToDecimal(value, isLatitude)
  if (decimalValue !== null && !isNaN(decimalValue)) {
    internalValueToUpdateOnBlur.value = coordinatesService.formatCoordinates(decimalValue, isLatitude)
    valueToUpdateOnBlur.value = decimalValue
  } else {
    internalValueToUpdateOnBlur.value = null
    valueToUpdateOnBlur.value = null
  }
}

const onBlur = () => {
  internalValue.value = internalValueToUpdateOnBlur.value
  emit("update:modelValue", valueToUpdateOnBlur.value)
  valueToUpdateOnBlur.value = null
  internalValueToUpdateOnBlur.value = null
}

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      internalValue.value = coordinatesService.formatCoordinates(newValue, props.coordinates === "latitude")
      valueToUpdateOnBlur.value = newValue
      internalValueToUpdateOnBlur.value = internalValue.value
    } else {
      internalValue.value = null
    }
  },
  { immediate: true }
)
</script>
