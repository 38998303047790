<template>
  <q-form ref="form" greedy no-error-focus>
    <fieldset :disabled="disable" :class="fieldsetClass">
      <slot />
    </fieldset>
  </q-form>
</template>

<script setup>
import { ref } from "vue"

const props = defineProps({
  disable: Boolean,
  fieldsetClass: String
})

const form = ref(null)

defineExpose({
  validate: () => {
    if (props.disable) {
      return true
    }
    return form.value.validate()
  }
})
</script>

<style lang="scss" scoped>
fieldset {
  border: none;
  padding: 0;
}
</style>
