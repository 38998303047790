import axios from "axios"

export const getFileExtension = (fileName) => {
  if (!fileName) {
    return ""
  }
  const patt = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/
  const match = fileName.toLowerCase().match(patt)
  return match && match.length > 0 ? match[0] : ""
}

export const getFileNameWithoutExtension = (fileName) => {
  if (!fileName) {
    return ""
  }
  return fileName.substr(0, fileName.lastIndexOf(".")) || fileName
}

export const removeGuidFromFileName = (fileName) => {
  const guidPattern = /[-_][a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/

  const nameWithoutExt = getFileNameWithoutExtension(fileName)
  const ext = getFileExtension(fileName)

  const nameWithoutGuid = nameWithoutExt.replace(guidPattern, "").trim()

  return nameWithoutGuid + ext
}

export const getTypeForFile = (fileName) => {
  const ext = getFileExtension(fileName).toLowerCase()
  switch (ext) {
    case ".pdf":
      return "PDF"
    case ".xls":
    case ".xlsx":
      return "EXCEL"
    case ".doc":
    case ".docx":
      return "WORD"
    case ".txt":
      return "TEXT"
    case ".url":
      return "URL"
    case ".img":
    case ".jpg":
    case ".jpeg":
    case ".png":
    case ".bmp":
    case ".gif":
      return "IMAGE"
    default:
      return ""
  }
}

const predictDelimiter = (text) => {
  const commaCount = (text.match(/,/g) || []).length
  const semicolonCount = (text.match(/;/g) || []).length
  return commaCount > semicolonCount ? "," : ";"
}

const getLinesFromText = (allText) => {
  const allTextLines = allText.split(/\r\n|\n/)
  const delimiter = predictDelimiter(allText)
  const headers = allTextLines[0].split(delimiter)
  const lines = []

  for (let i = 1; i < allTextLines.length; i++) {
    const data = allTextLines[i].split(delimiter)
    if (data.length === headers.length) {
      const tarr = headers.map((header, j) => ({
        header,
        data: data[j]
      }))
      lines.push(tarr)
    }
  }
  return lines
}

export const getLinesFromCsvFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener("load", () => resolve(getLinesFromText(reader.result)), false)

    if (file) {
      reader.readAsText(file, "windows-1252")
    } else {
      reject(new Error("No file provided"))
    }
  })
}

const processRow = (row) => {
  return (
    row
      .map((cell) => {
        let innerValue = cell == null ? "" : cell.toString()
        if (cell instanceof Date) {
          innerValue = cell.toLocaleString()
        }
        let result = innerValue.replace(/"/g, "\"\"")
        if (result.search(/("|;|\n)/g) >= 0) {
          result = `"${result}"`
        }
        return result
      })
      .join(";") + "\n"
  )
}

export const generateAndDownloadCsv = (fileName, rows) => {
  let csvFile = rows.map(processRow).join("")
  const BOM = "\uFEFF"
  csvFile = BOM + csvFile

  const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" })
  downloadBlob(blob, fileName)
}

export const generateAndDownloadJson = (fileName, jsonObject) => {
  const blob = new Blob([JSON.stringify(jsonObject)], { type: "application/json;charset=utf-8;" })
  downloadBlob(blob, fileName)
}

export const downloadGeneratedPdf = (fileName, data) => {
  const blob = new Blob([data], { type: "application/pdf" })
  downloadBlob(blob, fileName)
}

export const downloadGeneratedXlsx = (fileName, data) => {
  const blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  })
  downloadBlob(blob, fileName)
}

const downloadBlob = (blob, filename) => {
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement("a")
    if (link.download !== undefined) {
      // Feature detection
      const url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", filename)
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export const downloadFile = (src) =>
  axios.get(src, {
    responseType: "arraybuffer"
  })
