import { ref } from "vue"
import { defineStore } from "pinia"

export const useAppStateStore = defineStore(
  "appState",
  () => {
    const rememberMe = ref(false)
    const nextMaintenanceIgnoredIds = ref([])
    const changingModule = ref(false)

    const addIgnoredId = (id) => {
      if (!nextMaintenanceIgnoredIds.value.includes(id)) {
        nextMaintenanceIgnoredIds.value.push(id)
      }
    }

    const loadIgnoredIds = (ids) => {
      nextMaintenanceIgnoredIds.value = ids
    }

    const setChangingModule = (value) => {
      changingModule.value = value
    }

    return {
      rememberMe,
      nextMaintenanceIgnoredIds,
      changingModule,
      addIgnoredId,
      loadIgnoredIds,
      setChangingModule
    }
  },
  {
    persist: {
      storage: localStorage,
      paths: ["rememberMe", "nextMaintenanceIgnoredIds"]
    }
  }
)
