import { computed } from "vue"

import * as dates from "@/services/dates.js"

import * as accountApi from "@/api/main/accountApi"

import i18n from "@/plugins/i18n"

export function useValidators() {
  const nationalCompanyNumber = (companyCountry, errorI18n) =>
    computed(() => {
      if (companyCountry.value !== "F") {
        return () => true
      }

      const patt = new RegExp("^\\d\\d\\d\\d\\d\\d\\d\\d\\d$")
      return (val) => !val || patt.test(val) || i18n.global.t(errorI18n)
    })

  const nationalInstitutionNumber = (companyCountry, nationalCompanyNumber, nationalInstitutionNumber) =>
    computed(() => {
      if (companyCountry.value !== "F") {
        return () => true
      }
      const numbersMatch = nationalInstitutionNumber.value?.substring(0, 9) === nationalCompanyNumber.value
      return (val) => !val || (val.length === 14 && numbersMatch) || i18n.global.t("ACCOUNT_PAGE_REPRESENTATIVE_NATIONAL_INSTITUTION_NUMBER_WARNING")
    })

  const nationalNumber1 = (companyCountry, errorI18n) =>
    computed(() => {
      if (companyCountry.value !== "F") {
        return () => true
      }

      const patt = new RegExp("^\\d\\d(\\d|A|B)\\d\\d\\d\\d\\d\\d\\d$")
      return (val) => !val || patt.test(val) || i18n.global.t(errorI18n)
    })

  const postCode = (companyCountry, errorI18n) =>
    computed(() => {
      if (companyCountry.value !== "F") {
        return () => true
      }
      return (val) => {
        if (!val) {
          return true
        }
        const value = parseInt(val, 10)
        const valid = val.length === 5 && ((value >= 1000 && value <= 95999) || (value >= 97100 && value <= 97699) || (value >= 98600 && value <= 98899))
        return valid || i18n.global.t(errorI18n)
      }
    })

  const companyCityCode = (companyCountry) =>
    computed(() => {
      if (companyCountry.value !== "F") {
        return () => true
      }
      return (val) => !val || val.length <= 5 || i18n.global.t("_SHARED_CITY_CODE_WARNING")
    })

  const doesUserNameExist = async (userName) => {
    try {
      const response = await accountApi.doesUserNameExist(userName)
      return response.value
    } catch (error) {
      return false
    }
  }

  const userName = (loginBlackList, initialLogin) =>
    computed(() => {
      return async (val) => {
        if (!val || val === initialLogin.value) {
          return true
        }

        const existsInBlackList = loginBlackList.value.includes(val)
        const exists = await doesUserNameExist(val)
        return !(exists || existsInBlackList) || i18n.global.t("ACCOUNT_PAGE_LOGIN_TAKEN_WARNING")
      }
    })

  const compareTo = (compareValue) =>
    computed(() => {
      return (val) => !val || val === compareValue.value || i18n.global.t("_RSFAP_SHARED_INVALID_DATA")
    })

  const laterOrEqualDate = (compareDate, errorI18n) =>
    computed(() => {
      return (val) => !val || dates.isAfterOrEqual(new Date(val), compareDate.value) || i18n.global.t(errorI18n)
    })

  const required = (val) => (val !== null && val !== undefined && val !== "") || i18n.global.t("_RSFAP_SHARED_REQUIRED")

  const url = (val) => !val || isValidUrl(val) || i18n.global.t("_RSFAP_SHARED_INVALID_DATA")

  const latin = (val) => !val || /^[A-Za-z0-9 _-]+$/.test(val) || i18n.global.t("_SHARED_ACCENTED_LETTERS_WARNING")

  const email = (val) => !val || /.+@.+\..+/.test(val) || i18n.global.t("_RSFAP_SHARED_INVALID_DATA")

  const date = (val) => !val || dates.isDateStringValid(val) || i18n.global.t("_RSFAP_SHARED_INVALID_DATA")

  const isValidUrl = (url) => {
    try {
      new URL(url)
      return true
    } catch (e) {
      return false
    }
  }

  return {
    nationalCompanyNumber,
    nationalInstitutionNumber,
    nationalNumber1,
    postCode,
    companyCityCode,
    laterOrEqualDate,
    compareTo,
    userName,
    required,
    url,
    latin,
    email,
    date
  }
}
