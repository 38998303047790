import { computed, ref } from "vue"

import { defineStore } from "pinia"

import Cookies from "js-cookie"

import * as sessionApi from "@/api/sessionApi.js"

import eventBus from "@/plugins/eventBus.js"
import router, { defaultRedirect } from "@/plugins/router.js"

import { useAppStateStore } from "@/stores/appState.js"
import { useGlobalStore } from "@/stores/global.js"

import { routeNames } from "@/constants/routes.js"

const storageOverride = {
  getItem(key) {
    const appStateStore = useAppStateStore()
    const storage = appStateStore.rememberMe ? window.localStorage : window.sessionStorage
    return storage.getItem(key)
  },
  setItem(key, value) {
    const appStateStore = useAppStateStore()
    const storage = appStateStore.rememberMe ? window.localStorage : window.sessionStorage
    storage.setItem(key, value)
  }
}

export const useSessionStore = defineStore(
  "session",
  () => {
    const session = ref(null)
    const userInfo = ref(null)
    const loggingIn = ref(false)
    const loggingOut = ref(false)

    const webSsoError = ref(null)
    const webSsoFailed = computed(() => webSsoError.value !== null)

    const accessToken = computed(() => session.value?.access_token)

    const isAdmin = computed(() => userInfo.value?.roles?.includes("PORTADMIN"))
    const isRegulator = computed(() => userInfo.value?.roles?.includes("REGULATOR"))
    const isLoggedIn = computed(() => Boolean(session.value && userInfo.value))

    const userRequestSystems = computed(() => {
      if (!userInfo.value) {
        return []
      }
      const allPossibleRequestSystems = []
      userInfo.value.relationsInfo.forEach((relInfo) => {
        relInfo.requestSystems.forEach((system) => {
          if (!allPossibleRequestSystems.includes(system)) {
            allPossibleRequestSystems.push(system)
          }
        })
      })
      return allPossibleRequestSystems
    })

    const usersAllowedForRequestFor = (requestSystem) => {
      if (!userInfo.value) {
        return []
      }

      return userInfo.value.relationsInfo
        .filter((relInfo) => relInfo.requestSystems.includes(requestSystem))
        .map((relInfo) => ({
          id: relInfo.parentInfo.id,
          companyName: relInfo.parentInfo.companyName,
          availableOperators: relInfo.availableOperators
        }))
    }

    const logIn = async ({ username, password }) => {
      try {
        loggingIn.value = true
        session.value = await sessionApi.requestToken({ username, password })
        await setUserData()
        defaultRedirect()
      } catch (err) {
        clearSession()
        throw err
      } finally {
        loggingIn.value = false
      }
    }

    const setUserData = async () => {
      userInfo.value = await sessionApi.getCurrentUserSettings()
    }

    const refreshSession = async () => {
      try {
        session.value = await sessionApi.refreshToken()
        eventBus.$emit("access-token-refreshed")
        await setUserData()
      } catch (err) {
        clearSession()
        throw err
      }
    }

    const handleWebSso = async () => {
      try {
        webSsoError.value = null
        const res = await sessionApi.fetchWebSsoSettings()
        session.value = { access_token: res.access_token }
      } catch (err) {
        webSsoError.value = err?.response?.data?.values || { error: "Unknown" }
        throw err
      }
    }

    const handleSamlCookie = () => {
      const accessToken = Cookies.get("X-ICSP-ACCESS-TOKEN")

      if (accessToken) {
        session.value = { access_token: accessToken }
        Cookies.remove("X-ICSP-ACCESS-TOKEN")
      }
    }

    const clearSession = () => {
      session.value = userInfo.value = null
    }

    const logOut = async () => {
      let redirected = false
      try {
        const globalStore = useGlobalStore()
        eventBus.$emit("before-log-out")
        loggingOut.value = true

        const oldAccessToken = accessToken.value
        clearSession()

        if (globalStore.globalConfig.ssoSettings.saml.enabled && globalStore.globalConfig.ssoSettings.saml.singleLogoutEnabled) {
          sessionApi.redirectToSamlLogout(oldAccessToken)
          return
        }

        await sessionApi.removeRefreshCookie()

        const customSso = globalStore.globalConfig.ssoSettings.customSso

        if (customSso.enabled) {
          const redirectUrl = customSso.portalUrl + (customSso.forcePortalLogout ? "?logout=1" : "")
          window.location.replace(redirectUrl)
          redirected = true
        } else {
          await router.push({ name: routeNames.mainLogin })
        }
      } finally {
        // Prevent continuation when redirected
        if (!redirected) {
          loggingOut.value = false
        }
      }
    }

    return {
      session,
      accessToken,
      isLoggedIn,
      userRequestSystems,
      usersAllowedForRequestFor,
      clearSession,
      refreshSession,
      userInfo,
      isAdmin,
      isRegulator,
      loggingIn,
      loggingOut,
      webSsoError,
      webSsoFailed,
      logIn,
      handleWebSso,
      handleSamlCookie,
      logOut
    }
  },
  {
    persist: {
      storage: storageOverride,
      paths: ["session"]
    }
  }
)
