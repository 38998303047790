import * as dates from "@/services/dates.js"

export const stringCompare = (a, b, prop) => {
  const aVal = a?.[prop] ?? ""
  const bVal = b?.[prop] ?? ""
  return aVal.localeCompare(bVal)
}

export const dateCompare = (a, b, prop, defVal = 0) => {
  const aTime = dates.convertDateStringToUniversalMiliseconds(a?.[prop]) ?? defVal
  const bTime = dates.convertDateStringToUniversalMiliseconds(b?.[prop]) ?? defVal
  return aTime - bTime
}

export const numberCompare = (a, b, prop) => {
  const aVal = parseFloat(a?.[prop]) || 0
  const bVal = parseFloat(b?.[prop]) || 0

  return aVal - bVal
}

export const compare = (a, b, prop) => {
  const aVal = a?.[prop] ?? ""
  const bVal = b?.[prop] ?? ""

  if (typeof aVal === "number" && typeof bVal === "number") {
    return aVal - bVal
  } else {
    return aVal.localeCompare(bVal)
  }
}

export const isNullOrEmpty = (value) => value == null || value === ""
