<template>
  <app-input :model-value="valueFormattedForCurrentLanguage" :disable="disable" :readonly="readonly" date>
    <template v-if="!disable && !readonly" #append>
      <q-icon name="event" class="cursor-pointer">
        <q-popup-proxy ref="popup" cover transition-show="scale" transition-hide="scale">
          <q-date :model-value="modelValue" mask="YYYY-MM-DD" @update:model-value="onValueChange">
            <div class="row items-center justify-end gap-1">
              <app-button size="sm" :label="$t('_RSFAP_SHARED_CALENDAR_CLEAR')" @click="clear" />
              <app-button size="sm" :label="$t('_RSFAP_SHARED_CALENDAR_TODAY')" @click="setToday" />
              <q-space />
              <app-button size="sm" v-close-popup :label="$t('_RSFAP_SHARED_CLOSE')" />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </app-input>
</template>

<script setup>
import { computed, ref } from "vue"

import * as dates from "@/services/dates.js"

const props = defineProps({
  modelValue: {
    type: String,
    default: null
  },
  disable: Boolean,
  readonly: Boolean
})

const emit = defineEmits(["update:modelValue"])

const popup = ref(null)

const valueFormattedForCurrentLanguage = computed(() => dates.getLocalizedStringFromDateTime(props.modelValue))

const onValueChange = (value) => {
  popup.value.hide()
  emit("update:modelValue", value)
}

const clear = () => onValueChange(null)
const setToday = () => onValueChange(dates.getApiStringFromDate(new Date(), true))
</script>
