import axios from "axios"

export const getExternalApplicants = () => axios.get("/api/main/users/getExternalApplicants")

export const doesUserNameExist = (userName) =>
  axios.get("/api/main/users/doesUserNameExist", {
    params: { userName }
  })

export const getCurrentUserFullInfo = () => axios.get("/api/main/users/getCurrentUserFullInfo")

export const getFullUserInfoForRelatedContact = () => axios.get("/api/main/users/getFullUserInfoForRelatedContact")

export const getConnectedUsers = () => axios.get("/api/main/users/getConnectedUsers")

export const deleteUserRequest = (data) => axios.post("/api/main/account/deleteUserRequest", data)

export const updateContactRequest = (data) => axios.post("/api/main/account/updateContactRequest", data)

let surveysShowForUserCached = null

export const getSurveysShowForUser = async () => {
  if (!surveysShowForUserCached) {
    surveysShowForUserCached = axios.get("/api/surveys/appSettings/showForUser")
  }

  return await surveysShowForUserCached
}

export const updateUserRequest = ({ representative, files }) => {
  const formData = new FormData()
  formData.append("representative", JSON.stringify(representative))

  files.forEach((file, index) => {
    formData.append(`files[${index}]`, file)
  })

  return axios.post("/api/main/account/updateUserRequest", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}

export const sendRegistrationRequest = ({ representative, files }) => {
  const formData = new FormData()
  formData.append("representative", JSON.stringify(representative))

  files.forEach((file, index) => {
    formData.append(`files[${index}]`, file)
  })

  return axios.post("/api/main/account/sendRegistrationRequest", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}
