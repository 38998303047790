<template>
  <q-scroll-area class="fit">
    <q-list>
      <q-expansion-item :label="$t('_RSFAP_SHARED_HEADER_MODULES')">
        <q-list>
          <q-item v-for="moduleInfo in modules" :key="moduleInfo.key" clickable>
            <q-item-section>{{ moduleInfo.title }}</q-item-section>
          </q-item>
        </q-list>
      </q-expansion-item>
      <q-item clickable dark :to="{ name: routeNames.mainHelp }">
        <q-item-section>
          {{ $t("_RSFAP_SHARED_HEADER_HELP") }}
        </q-item-section>
      </q-item>
      <q-item clickable dark :to="{ name: routeNames.mainDocumentation }">
        <q-item-section>
          {{ $t("_RSFAP_SHARED_HEADER_DOCUMENTATION") }}
        </q-item-section>
      </q-item>
    </q-list>
  </q-scroll-area>
</template>

<script setup>
import { computed, ref } from "vue"
import { useRoute } from "vue-router"
import { storeToRefs } from "pinia"

import { useGlobalStore } from "@/stores/global.js"
import { useSessionStore } from "@/stores/session.js"

import { getModulesDisplayInfo } from "@/constants/main/modules.js"

import * as accountApi from "@/api/main/accountApi.js"

import { routeNames } from "@/constants/routes.js"

const route = useRoute()

const globalStore = useGlobalStore()
const { userInfo, userRequestSystems } = storeToRefs(useSessionStore())

const surveysShowForUser = ref(false)

const validateEnabledRequestSystem = (system) => globalStore.globalSettings.enabledRequestSystems.includes(system) && userRequestSystems.value.includes(system)
const validateRoles = (requiredRoles) => requiredRoles.some((role) => userInfo.value.roles.includes(role))
const validateOrigins = (requiredOrigins) => requiredOrigins.some((origin) => userInfo.value.origin === origin)

const settingsMode = computed(() => Boolean(route.params.mode))

const modules = computed(() =>
  getModulesDisplayInfo().filter((module) => {
    const isEnabled = globalStore.globalConfig.modules[module.key] && globalStore.globalSettings.enabledModules.includes(module.key)
    const isSettingsMode = module.settingsOnly && settingsMode.value
    const hasRequiredRequestSystems = !module.requiredRequestSystems || module.requiredRequestSystems.every(validateEnabledRequestSystem)
    const hasRequiredRoles = !module.requiredRoles || validateRoles(module.requiredRoles)
    const hasRequiredOrigins = !module.requiredOrigins || validateOrigins(module.requiredOrigins)
    const hasSurveyCheck = !module.requiresSurveyCheck || surveysShowForUser.value

    return (isEnabled || isSettingsMode) && hasRequiredRequestSystems && hasRequiredRoles && hasRequiredOrigins && hasSurveyCheck
  })
)

const fetchSurveyData = async () => {
  const isSurveyModuleEnabled = globalStore.globalConfig.modules.surveys && globalStore.globalSettings.enabledModules.includes("surveys")
  if (!isSurveyModuleEnabled) {
    return
  }
  surveysShowForUser.value = await accountApi.getSurveysShowForUser()
}

fetchSurveyData()
</script>

<style lang="scss" scoped>
.q-item--dark.q-router-link--active {
  color: $accent !important;
}
</style>
