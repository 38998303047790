import { Notify, Loading, Dialog } from "quasar"

import DOMPurify from "dompurify"

export const showToast = ({ message }) =>
  Notify.create({
    message,
    timeout: 3000,
    color: "positive"
  })

export const showErrorToast = ({ message, timeout }) =>
  Notify.create({
    message,
    color: "negative",
    timeout: timeout ?? 5000
  })

export const showLoading = () => Loading.show({ delay: 100 })

export const hideLoading = () => Loading.hide()

export const showDialog = ({ cancel, ...options }) =>
  Dialog.create({
    ...options,
    message: options.html ? DOMPurify.sanitize(options.message) : options.message,
    persistent: true,
    cancel: cancel
      ? {
          outline: true
        }
      : null,
    ok: {
      color: "primary"
    }
  })
