import { transformExtent, transform } from "ol/proj"
import { containsExtent, intersects, getCenter, extend, createEmpty } from "ol/extent"
import { Point } from "ol/geom"

const R = 6373e3 // metres

export const geoExtentToWebMatrix = (extent) => transformExtent(extent, "EPSG:4326", "EPSG:3857")

export const geoCoordsToWebMatrix = (arr) => transform([parseFloat(arr[0]), parseFloat(arr[1])], "EPSG:4326", "EPSG:3857")

export const webMatrixCoordsToGeo = (arr) => transform([parseFloat(arr[0]), parseFloat(arr[1])], "EPSG:3857", "EPSG:4326")

export const webMatrixExtentToGeo = (extent) => transformExtent(extent, "EPSG:3857", "EPSG:4326")

export const decimalToDegreesAbsoluteValues = (value, roundSeconds) => {
  value = Math.abs(value)
  const degrees = Math.trunc(value)
  const minutes = Math.round(Math.trunc((value * 60) % 60))
  let seconds = Number((value * 3600) % 60).toFixed(2)

  if (roundSeconds) {
    seconds = Math.floor(seconds)
  }

  return {
    degrees,
    minutes,
    seconds
  }
}

export const decimalToDegreesAbsolute = (value, roundSeconds) => {
  const data = decimalToDegreesAbsoluteValues(value, roundSeconds)
  return `${data.degrees}° ${data.minutes}' ${data.seconds}''`
}

export const decimalToDegreesLongitude = (value, roundSeconds) => `${decimalToDegreesAbsolute(value, roundSeconds)} ${value >= 0 ? "E" : "W"}`

export const decimalToDegreesLatitude = (value, roundSeconds) => `${decimalToDegreesAbsolute(value, roundSeconds)} ${value >= 0 ? "N" : "S"}`

export const decimalToDegrees = (longitude, latitude) => `${decimalToDegreesLongitude(longitude)}, ${decimalToDegreesLatitude(latitude)}`

export const degreesToDecimal = (degrees, minutes, seconds, direction) => direction * degrees + (direction * minutes) / 60 + (direction * seconds) / 3600

export const convertCoordinatesToDecimal = (value, isLatitude) => {
  const coordsInfo = {
    type: isLatitude ? "latitude" : "longitude",
    positive: isLatitude ? "N" : "E",
    negative: isLatitude ? "S" : "W"
  }

  value = value.trim().replace(/\s+/g, " ")

  if (!isNaN(value)) {
    return parseFloat(value)
  }

  // Support for digits with spaces
  const coordsOnlyRegex = /^(\d+\s+\d+\s+\d+\.?\d*)/
  const directionRegex = new RegExp(
    `\\s+(${coordsInfo.positive}|${coordsInfo.negative}|${coordsInfo.positive.toLowerCase()}|${coordsInfo.negative.toLowerCase()})$`
  )
  const coordsWithSpacesRegex = new RegExp(coordsOnlyRegex.source + directionRegex.source)

  if (coordsOnlyRegex.test(value) && !coordsWithSpacesRegex.test(value)) {
    value += ` ${coordsInfo.positive}`
  }

  if (coordsWithSpacesRegex.test(value)) {
    const matchedCords = value.match(coordsOnlyRegex)[0].replace(/  +/g, " ")
    const matchedDir = value.match(directionRegex)[0].trim()
    const splittedCords = matchedCords.split(" ")

    const degrees = parseInt(splittedCords[0], 10)
    const minutes = parseInt(splittedCords[1], 10)
    const seconds = parseFloat(splittedCords[2])
    const direction = matchedDir.toUpperCase()
    const multiplier = (isLatitude && direction === coordsInfo.negative) || (!isLatitude && direction === coordsInfo.negative) ? -1 : 1

    return degreesToDecimal(degrees, minutes, seconds, multiplier)
  }

  const coordsRegex = /^(\d+)°\s*(\d+)'?\s*(\d+\.\d+|\d+)''?\s*([NSEWnsew]?)$/
  const match = value.match(coordsRegex)

  // Support for coordinates with degrees, minutes and seconds
  if (match) {
    const degrees = parseInt(match[1], 10)
    const minutes = parseInt(match[2], 10)
    const seconds = parseFloat(match[3])
    const direction = match[4] ? match[4].toUpperCase() : null
    const multiplier = (isLatitude && direction === coordsInfo.negative) || (!isLatitude && direction === coordsInfo.negative) ? -1 : 1
    return degreesToDecimal(degrees, minutes, seconds, multiplier)
  }

  return null
}

export const formatCoordinates = (value, isLatitude) => {
  return isLatitude ? decimalToDegreesLatitude(value) : decimalToDegreesLongitude(value)
}

export const isPointInsideExtent = (point, extent) => containsExtent(extent, Point(point).getExtent())

export const doExtentsOverlap = (extent1, extent2) => intersects(extent1, extent2)

export const isPointInsideCircle = (lat1, lat2, lon1, lon2, radius) => calculateDistance(lat1, lat2, lon1, lon2) <= radius

export const getCenterOfExtent = (extent) => getCenter(extent)

export const calculateDistance = (lat1, lat2, lon1, lon2) => {
  const r1 = degToRad(lat1)
  const r2 = degToRad(lat2)
  const rd1 = degToRad(lat2 - lat1)
  const rd2 = degToRad(lon2 - lon1)

  const a = Math.sin(rd1 / 2) * Math.sin(rd1 / 2) + Math.cos(r1) * Math.cos(r2) * Math.sin(rd2 / 2) * Math.sin(rd2 / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}

export const degToRad = (deg) => deg * (Math.PI / 180)

export const convertCoordsToPoints = (coords) =>
  coords.reduce((points, coord) => {
    if (coord.lon === undefined || coord.lat === undefined) {
      return points
    }
    const pointsInWebMatrix = geoCoordsToWebMatrix([coord.lon, coord.lat])
    points.push(pointsInWebMatrix)
    return points
  }, [])

export const getSingleExtent = (extents, geoLocalized) => {
  let singleExtent = null
  const defaultExtents = extents.find((e) => e.default)
  if (extents.length === 1) {
    singleExtent = geoExtentToWebMatrix(extents[0])
  } else if (defaultExtents && geoLocalized) {
    const defaultExtent = geoExtentToWebMatrix(defaultExtents)
    const centerPoint = getCenterOfExtent(defaultExtent)
    if (isPointInsideExtent(centerPoint, defaultExtent)) {
      singleExtent = defaultExtent
    }
  }
  if (!singleExtent) {
    singleExtent = createEmpty()
    extents.forEach((e) => extend(singleExtent, geoExtentToWebMatrix(e)))
  }
  return singleExtent
}

export const moveCoordinates = (lon, lat, dx, dy) => {
  const newLatitude = Number(lat) + (dy / R) * (180 / Math.PI)
  const newLongitude = Number(lon) + ((dx / R) * (180 / Math.PI)) / Math.cos((lat * Math.PI) / 180)
  return [newLongitude, newLatitude]
}
