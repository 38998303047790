<template>
  <q-list bordered dense>
    <template v-for="(slot, name) in $slots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope ?? {}" />
    </template>
  </q-list>
</template>

<style lang="scss" scoped>
:deep(.q-item--active) {
  background-color: var(--q-primary);
  color: white;
}
</style>
