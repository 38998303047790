import { ref, watch } from "vue"
import { defineStore, storeToRefs } from "pinia"

import { useTranslationsStore } from "@/stores/translations.js"

import * as dictionariesApi from "@/api/dictionariesApi.js"

export const useDictionariesStore = defineStore("dictionaries", () => {
  const dictionaries = ref({})
  const { currentLanguage } = storeToRefs(useTranslationsStore())

  const fetchDictionaries = async () => {
    dictionaries.value = await dictionariesApi.getDictionaries({ locale: currentLanguage.value })
  }

  const getTranslation = (dictionary, key) => dictionaries.value[dictionary]?.find((item) => item.Key === key)?.Value || key

  const getDictionary = (dictionary) => dictionaries.value[dictionary] || []

  const getFormattedDictionaryValue = (key, value, toUpperCase) => {
    if (!key) {
      return ""
    }
    return `${key} - ${toUpperCase ? value.toUpperCase() : value}`
  }

  const getFormattedDictionaryValueFromDictionary = (dictionaryName, key, toUppercase) => {
    if (key === undefined || key === null) {
      return ""
    }

    key = key.toString()
    const dictionary = getDictionary(dictionaryName)

    if (!dictionary) {
      return key
    }

    const dictItem = dictionary.find((item) => item.Key === key)
    return dictItem ? getFormattedDictionaryValue(key, dictItem.Value, toUppercase) : key
  }

  const isKeyInDictionary = (dictionaryName, key) => Boolean(getDictionary(dictionaryName).find((item) => item.Key === key))

  const getValueForKey = (dictionaryName, key) => {
    if (key == null) {
      return ""
    }

    key = key.toString()

    const dictionary = getDictionary(dictionaryName)

    if (!dictionary) {
      return key
    }

    const dictItem = dictionary.find((item) => item.Key === key)
    return dictItem ? dictItem.Value : key
  }

  const addDictionary = (dictionaryName, dictionary) => (dictionaries.value[dictionaryName] = dictionary)

  watch(currentLanguage, fetchDictionaries)

  return {
    dictionaries,
    fetchDictionaries,
    getDictionary,
    getTranslation,
    getFormattedDictionaryValue,
    getFormattedDictionaryValueFromDictionary,
    isKeyInDictionary,
    getValueForKey,
    addDictionary
  }
})
