import { isValid, parse, format, isAfter } from "date-fns"

import { useTranslationsStore } from "@/stores/translations.js"

const apiFormat = "yyyy-MM-dd"

const getDateFormatForLanguage = (lang) => {
  switch (lang) {
    case "fr":
      return "dd-MM-yyyy"
    default:
      return "yyyy-MM-dd"
  }
}

const getLocalizedStringFromDate = (value) => {
  if (!value) {
    return ""
  }

  try {
    const translationsStore = useTranslationsStore()
    const outputFormat = getDateFormatForLanguage(translationsStore.currentLanguage)
    return format(new Date(value), outputFormat)
  } catch (error) {
    console.error(error)
    return ""
  }
}

export const convertDateStringToUniversalMiliseconds = (dateString) => {
  const date = isDateStringValid(dateString, apiFormat) ? new Date(dateString) : undefined
  if (date) {
    return date.getTime()
  }
  return 0
}

export const isDateStringValid = (dateString, format) => {
  const translationsStore = useTranslationsStore()
  const parsed = parse(dateString, format || getDateFormatForLanguage(translationsStore.currentLanguage), new Date())
  return isValid(parsed)
}

export const getApiStringFromDate = (date) => {
  if (!date) {
    return ""
  }
  try {
    return format(date, apiFormat)
  } catch (error) {
    console.error(error)
    return ""
  }
}

export const getTimeStringFromDate = (date) => {
  if (!date) {
    return ""
  }
  try {
    return format(date, "HH:mm")
  } catch (error) {
    console.error(error)
    return ""
  }
}

export const getLocalizedStringFromDateTime = (value, skipTime = false) => {
  if (!value) {
    return ""
  }
  const splitted = value.split(" ")

  let date = null
  if (isDateStringValid(splitted[0], apiFormat)) {
    date = parse(splitted[0], apiFormat, new Date())
  }

  if (!date) {
    return ""
  }

  let ret = getLocalizedStringFromDate(date)

  if (splitted.length > 1 && !skipTime) {
    ret += " " + splitted[1]
  }
  return ret
}

export const isAfterOrEqual = (date1, date2) => isAfter(date1, date2) || date1.toISOString().split("T")[0] === date2.toISOString().split("T")[0]
