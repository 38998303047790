import { Notify, Loading, Dialog } from "quasar"

import "@quasar/extras/material-icons/material-icons.css"
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css"

export default {
  plugins: {
    Notify,
    Loading,
    Dialog
  },
  extras: ["material-icons", "material-icons-outlined"],
  config: {
    notify: {},
    dark: false
  }
}
