<template>
  <q-layout view="hHh Lpr lff">
    <q-header v-if="messagesLoaded">
      <app-header>
        <template v-if="drawerSupported" #left>
          <app-button v-if="isLoggedIn" icon="menu" @click="drawer = !drawer" dense />
        </template>
      </app-header>
    </q-header>
    <q-drawer v-if="isLoggedIn && drawerSupported" v-model="drawer" :width="300" bordered dark show-if-above :breakpoint="700">
      <app-drawer />
    </q-drawer>
    <q-page-container v-if="showContent">
      <q-page :padding="!isFullSize">
        <router-view />
      </q-page>
    </q-page-container>
    <app-events-hub />
  </q-layout>
</template>

<script setup>
import { computed, ref, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import { storeToRefs } from "pinia"

import { useGlobalStore } from "@/stores/global.js"
import { useSessionStore } from "@/stores/session.js"
import { useAppStateStore } from "@/stores/appState.js"
import { useTranslationsStore } from "@/stores/translations.js"

import AppHeader from "@/components/commons/AppHeader.vue"
import AppDrawer from "@/components/commons/AppDrawer.vue"
import AppEventsHub from "@/components/commons/AppEventsHub.vue"

import * as signalRService from "@/services/signalRService.js"
import * as quasarUtils from "@/services/quasarUtils.js"

import { routeNames } from "@/constants/routes.js"

const router = useRouter()
const route = useRoute()

const globalStore = useGlobalStore()
const sessionStore = useSessionStore()

const { isLoggedIn, session, loggingOut } = storeToRefs(sessionStore)
const { refreshSession } = sessionStore

const { changingModule } = storeToRefs(useAppStateStore())

const { messagesLoaded } = storeToRefs(useTranslationsStore())

const initialized = ref(false)
const drawerSupported = ref(false)
const drawer = ref(false)

const showContent = computed(() => initialized.value && !loggingOut.value && !changingModule.value)

const fullSizeRoutes = [routeNames.mainUrlViewer, routeNames.mwParityMain]

const initSessionRefresh = () => {
  setInterval(() => {
    if (session.value) {
      refreshSession()
    }
  }, 50 * 60 * 1000)
}

const initSignalR = () => signalRService.init()

const isFullSize = computed(() => fullSizeRoutes.includes(route.name))

const init = async () => {
  try {
    if (globalStore.globalConfig.ssoSettings.customSso.enabled) {
      await sessionStore.handleWebSso()
    }

    initSessionRefresh()
    if (session.value) {
      await refreshSession()
      initSignalR()
    }
    initialized.value = true
  } catch (err) {
    console.error(err)
    sessionStore.clearSession()
    await router.push({ name: routeNames.mainLogin })
    initialized.value = true
  }
}

watch(session, (newValue, prevValue) => {
  if (newValue && !prevValue) {
    initSignalR()
  }
})

watch(
  showContent,
  (newValue) => {
    if (newValue) {
      quasarUtils.hideLoading()
    } else {
      quasarUtils.showLoading()
    }
  },
  { immediate: true }
)

init()
</script>

<style lang="scss">
@import "@/global.scss";

.view-container {
  max-width: 1400px;
  margin: 20px auto;
  padding: 40px 20px;

  &.full-size {
    max-width: none;
    margin: 0;
    padding: 0;
    border: none;
  }

  &.transparent {
    border: none !important;
    background-color: transparent !important;
  }
}
</style>
