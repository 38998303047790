<template>
  <q-checkbox dense :rules="outputRules" />
</template>

<script setup>
import { computed } from "vue"

import { useValidators } from "@/composables/useValidators"

const validators = useValidators()

const props = defineProps({
  required: Boolean
})

const outputRules = computed(() => {
  const rules = props.rules ? [...props.rules] : []
  if (props.required) {
    rules.push(validators.required)
  }
  return rules
})
</script>
