<template>
  <q-table
    ref="parent"
    class="app-table"
    :class="{ sticky, 'transparent-header': transparentHeader }"
    :rows="rows"
    :columns="outputColumns"
    row-key="id"
    :hide-bottom="!bottomDisplayed"
    wrap-cells
    flat
    bordered
    dense
    binary-state-sort
    :rows-per-page-options="[0]"
    separator="cell"
  >
    <template v-if="draggable" #body-cell-drag-handle="props">
      <q-td :props="props" auto-width>
        <app-button icon="drag_handle" dense class="drag-handle" />
      </q-td>
    </template>
    <template v-for="(slot, name) in $slots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope ?? {}" />
    </template>
  </q-table>
</template>

<script setup>
import { ref, computed, onMounted, useSlots } from "vue"

const slots = useSlots()

const props = defineProps({
  rows: {
    type: Array,
    default: () => []
  },
  columns: {
    type: Array,
    required: true
  },
  height: {
    type: String,
    default: null
  },
  draggable: {
    type: Boolean,
    default: false
  },
  transparentHeader: {
    type: Boolean,
    default: false
  },
  showBottom: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(["update:rows"])

const parent = ref(null)

const computedHeight = computed(() => props.height || "auto")

const sticky = computed(() => props.height !== null)

const bottomDisplayed = computed(() => (props.rows.length === 0 && slots["no-data"]) || props.showBottom)

const outputColumns = computed(() => {
  const ret = props.columns.map((column) => ({
    ...column,
    label: column.label,
    field: column.field || column.name,
    align: column.align || "left"
  }))

  if (props.draggable) {
    ret.unshift({ name: "drag-handle" })
  }

  return ret
})

const initDraggable = async () => {
  const { Sortable } = await import("sortablejs")
  Sortable.create(parent.value.$el.querySelector("tbody"), {
    handle: ".drag-handle",
    animation: 150,
    onUpdate({ oldIndex, newIndex, item, from }) {
      // cancel the UI update so vue will take care of it
      item.remove()
      if (oldIndex !== undefined) {
        from.insertBefore(item, from.children[oldIndex])
      }

      const newRows = [...props.rows]
      const [movedRow] = newRows.splice(oldIndex, 1)
      newRows.splice(newIndex, 0, movedRow)
      emits("update:rows", newRows)
    }
  })
}

onMounted(() => {
  if (props.draggable) {
    initDraggable()
  }
})
</script>

<style scoped lang="scss">
.app-table {
  max-height: v-bind(computedHeight);

  :deep(thead tr:first-child th, .q-table__top, .q-table__top) {
    background-color: $dark;
    color: white;
    text-align: center;
    font-size: 0.9rem;

    button {
      color: white !important;
      text-transform: capitalize;
    }
  }

  &.transparent-header {
    background-color: transparent !important;
    color: white;

    :deep(thead tr:first-child th) {
      border-top: 1px solid;
    }

    :deep(td) {
      border-color: white;
    }
  }

  &.no-wrap {
    :deep(td),
    :deep(th) {
      white-space: nowrap;
    }
  }

  :deep(thead tr:first-child th) {
    border-color: white;
  }

  :deep(.q-linear-progress) {
    color: red !important;
  }

  //to have the same height when there is button in the header
  :deep(thead tr:first-child) {
    height: 40px;
  }

  &.sticky {
    :deep(thead tr th) {
      position: sticky;
      z-index: 1;
    }

    :deep(thead tr:first-child th) {
      top: 0;
    }

    &.q-table--loading :deep(thead tr:last-child th) {
      top: 48px;
    }

    //prevent scrolling behind sticky top row on focus
    :deep(tbody) {
      //height of all previous header rows
      scroll-margin-top: 48px;
    }
  }
}
</style>
