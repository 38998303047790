import axios from "axios"

import { useSessionStore } from "@/stores/session.js"
import { useGlobalStore } from "@/stores/global.js"

import router from "@/plugins/router.js"

import { routeNames } from "@/constants/routes.js"

export const setupAxios = () => {
  axios.interceptors.request.use(
    (config) => {
      const sessionStore = useSessionStore()

      if ((config.url.startsWith("/api/") || config.url.startsWith("/token/")) && Boolean(sessionStore.session)) {
        config.headers.Authorization = `Bearer ${sessionStore.accessToken}`
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  axios.interceptors.response.use(
    (response) => {
      if (response.data) {
        return response.data
      }

      return response
    },
    (error) => {
      if (error.response.status === 401) {
        const sessionStore = useSessionStore()
        sessionStore.clearSession()
        router.push({ name: routeNames.mainLogin })
      }

      if (error.response.status === -1) {
        const globalStore = useGlobalStore()
        if (globalStore.globalConfig.ssoSettings.customSso.enabled) {
          globalStore.redirectToSsoPortal()
        }
      }
      return Promise.reject(error)
    }
  )
}

const cache = {}

const generateCacheKey = (url, params) => {
  return `${url}:${JSON.stringify(params)}`
}

export const fetchDataWithCache = async (url, params) => {
  const cacheKey = generateCacheKey(url, params)
  if (cache[cacheKey]) {
    return cache[cacheKey]
  }

  const response = await axios.get(url, { params })
  cache[cacheKey] = response
  return response
}
