import { computed } from "vue"

export function useSelection(items) {
  const allSelected = computed({
    get: () => items.value.length > 0 && items.value.every((item) => item.selected),
    set: (value) => {
      items.value.forEach((item) => {
        item.selected = value
      })
    }
  })

  return {
    allSelected
  }
}
