import axios from "axios"

import { useAppStateStore } from "@/stores/appState.js"

const createDataFromParams = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&")

export const requestToken = ({ username, password }) => {
  const appStateStore = useAppStateStore()

  const params = {
    username,
    password,
    grant_type: "password",
    rememberMe: appStateStore.rememberMe
  }

  return axios.post("/token", createDataFromParams(params))
}

export const refreshToken = () => {
  const appStateStore = useAppStateStore()
  return axios.post(
    "/token",
    createDataFromParams({
      grant_type: "refresh_token",
      refresh_token: "",
      rememberMe: appStateStore.rememberMe
    })
  )
}

export const getCurrentUserSettings = () => axios.get("/api/main/auth/getCurrentUserSettings")

export const removeRefreshCookie = () => axios.post("/api/main/auth/removeRefreshCookie")

export const changePassword = (data) => axios.post("/api/main/auth/changePassword", data)

export const resetPassword = (data) => axios.post("/api/main/auth/resetPassword", data)

export const requestPasswordResetToken = (data) => axios.post("/api/main/auth/requestPasswordResetToken", data)

export const fetchWebSsoSettings = () => axios.post("/api/main/auth/handleWebSsoIfNecessary")

export const redirectToSamlLogout = (accessToken) => {
  const form = document.createElement("form")
  form.method = "POST"
  form.action = `/api/main/auth/RedirectToSamlLogout?access_token=${accessToken}`
  document.body.appendChild(form)
  form.submit()
}
