import axios from "axios"

import { getModuleForRoute } from "@/plugins/router.js"

export const translations = ({ locale }) => axios.get(`/api/${getModuleForRoute()}/appSettings/getLocaleTranslations?locale=${locale}`)

export const adminTranslations = ({ locale }) => axios.get(`/api/${getModuleForRoute()}/appSettings/getLocaleTranslationsForAdmin?locale=${locale}`)

export const setLocaleTranslations = ({ locale, translations }) =>
  axios.post(`/api/${getModuleForRoute()}/appSettings/setLocaleTranslations`, { locale, translations })
