<template>
  <app-input ref="input" :model-value="internalValue" @update:model-value="handleUpdateModelValue" @blur="onBlur">
    <template v-for="(slot, name) in $slots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope ?? {}" />
    </template>
  </app-input>
</template>

<script setup>
import { ref, watch, nextTick } from "vue"

const props = defineProps({
  modelValue: [Number, String],
  integers: Boolean,
  minValue: Number,
  maxValue: Number,
  fixedDecimal: Number
})

const input = ref(null)
const emit = defineEmits(["update:modelValue"])

defineExpose({
  focus: () => input.value.focus()
})

const internalValue = ref(props.modelValue)
const temporaryValue = ref(internalValue.value)

const handleUpdateModelValue = (value) => {
  temporaryValue.value = value
}

const onBlur = async () => {
  let value = temporaryValue.value == null || temporaryValue.value === "" ? null : Number(temporaryValue.value)

  if (props.integers) {
    value = parseInt(value, 10)
  } else if (props.fixedDecimal && !isNaN(value)) {
    value = parseFloat(value.toFixed(props.fixedDecimal))
  }

  if (!isNaN(value)) {
    if (props.minValue != null && value < props.minValue) {
      value = props.minValue
    }
    if (props.maxValue != null && value > props.maxValue) {
      value = props.maxValue
    }
  } else {
    value = ""
  }

  //Force reactivity
  internalValue.value = null
  await nextTick()
  internalValue.value = value
  emit("update:modelValue", value)
}

watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue
    temporaryValue.value = newValue
  },
  { immediate: true }
)
</script>
