<template>
  <q-btn :color="color" :style="styles" :text-color="textColor">
    <slot />
    <q-tooltip v-if="$attrs.title" class="bg-primary">
      {{ $attrs.title }}
    </q-tooltip>
  </q-btn>
</template>

<script setup>
import { computed, useAttrs } from "vue"

import { useRoute } from "vue-router"

const route = useRoute()

const props = defineProps({
  styles: {
    type: Object,
    default: () => null
  }
})

const attrs = useAttrs()

const color = computed(() => (props.styles ? null : "dark"))

const routeMatchesToProp = computed(
  () => route.path === attrs.to || (route.name === attrs.to?.name && JSON.stringify(route.params || {}) === JSON.stringify(attrs.to?.params || {}))
)
const textColor = computed(() => {
  if (routeMatchesToProp.value) {
    return "accent"
  }

  return undefined
})
</script>
