<template>
  <q-tab-panels v-model="internalValue" keep-alive>
    <template v-for="(slot, name) in $slots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope ?? {}" />
    </template>
  </q-tab-panels>
</template>

<script setup>
import { ref, nextTick, watch } from "vue"

const props = defineProps({
  modelValue: String,
  tabs: Array
})

const initialValue = props.modelValue
const internalValue = ref(props.modelValue)

const forceRender = async () => {
  for (const tab of props.tabs.filter((tab) => tab.eager)) {
    internalValue.value = tab.name
    await nextTick()
  }
  internalValue.value = initialValue
}

defineExpose({ forceRender })

watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue
  }
)
</script>
