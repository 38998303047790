<template>
  <div ref="container" :style="{ height, overflow, maxHeight, minHeight }" class="fill-area full-width">
    <slot />
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount, onActivated } from "vue"

import eventBus from "@/plugins/eventBus.js"

const props = defineProps({
  offset: {
    type: Number,
    default: 0
  },
  overflow: {
    type: String,
    default: "auto"
  },
  useMaxHeight: {
    type: Boolean,
    default: false
  },
  useMinHeight: {
    type: Boolean,
    default: false
  },
  disable: {
    type: Boolean,
    default: false
  }
})

const height = ref("auto")
const maxHeight = ref("none")
const minHeight = ref("none")

const container = ref(null)

const updateStyleInternal = () => {
  height.value = "auto"
  maxHeight.value = "none"
  if (props.disable) {
    return
  }

  const parentDialog = container.value?.closest(".q-dialog")
  const inModal = Boolean(parentDialog)

  if (!container.value) {
    console.error("AppFillArea: Container not found")
    return
  }

  if (inModal && parentDialog.querySelector(".q-dialog__backdrop")?.className.includes("q-transition")) {
    //modal is still transitioning, calculations would be wrong
    return
  }

  const top = 50
  const elementTop = container.value.getBoundingClientRect().top
  const modalTop = inModal ? container.value.closest(".q-dialog .q-card").getBoundingClientRect().top : 0
  const containerHeight = inModal ? container.value.closest(".q-dialog").clientHeight : window.innerHeight
  const calculatedHeight = `${containerHeight - modalTop - elementTop - props.offset - top}px`

  if (props.useMaxHeight) {
    maxHeight.value = calculatedHeight
  }

  if (props.useMinHeight) {
    minHeight.value = calculatedHeight
  }

  if (!props.useMaxHeight && !props.useMinHeight) {
    height.value = calculatedHeight
  }
}

const updateStyle = () => {
  setTimeout(updateStyleInternal, 0)
}

watch(() => props.disable, updateStyle)
watch(() => props.offset, updateStyle)

onActivated(() => {
  updateStyle()
})

const mutationObserver = ref(null)

onMounted(() => {
  updateStyle()
  eventBus.$on("window-resize", updateStyle)

  const parentDialog = container.value?.closest(".q-dialog")

  if (parentDialog) {
    const dialogElementBackdrop = parentDialog.querySelector(".q-dialog__backdrop")
    if (dialogElementBackdrop) {
      mutationObserver.value = new MutationObserver((mutations) => {
        if (mutations.find((mutation) => mutation.attributeName === "class")) {
          updateStyle()
          mutationObserver.value.disconnect()
        }
      })

      mutationObserver.value.observe(dialogElementBackdrop, { attributes: true })
    }
  }
})

onBeforeUnmount(() => {
  eventBus.$off("window-resize", updateStyle)

  if (mutationObserver.value) {
    mutationObserver.value.disconnect()
  }
})
</script>
