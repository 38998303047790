const regex = /\/app\/([^#]*)\/#/
if (regex.test(window.location.href)) {
  window.location.replace(window.location.href.replace(regex, "/app/$1"))
}

import { createApp } from "vue"

import App from "@/App.vue"

import { Quasar } from "quasar"

import PortalVue from "portal-vue"

import pinia from "@/plugins/pinia.js"
import router from "@/plugins/router.js"
import i18n from "@/plugins/i18n.js"
import eventBus from "@/plugins/eventBus.js"

import "quasar/dist/quasar.sass"

import * as setup from "@/api/setup.js"

import AppButton from "@/components/commons/AppButton.vue"
import AppCard from "@/components/commons/AppCard.vue"
import AppInput from "@/components/commons/forms/AppInput.vue"
import AppNumberInput from "@/components/commons/forms/AppNumberInput.vue"
import AppCoordinatesInput from "@/components/commons/forms/AppCoordinatesInput.vue"
import AppDatePicker from "@/components/commons/forms/AppDatePicker.vue"
import AppTable from "@/components/commons/AppTable.vue"
import AppTabPanels from "@/components/commons/AppTabPanels.vue"
import AppSimpleTable from "@/components/commons/AppSimpleTable.vue"
import AppFormRules from "@/components/commons/forms/AppFormRules.vue"
import AppCheckbox from "@/components/commons/forms/AppCheckbox.vue"
import AppSelect from "@/components/commons/forms/AppSelect.vue"
import AppRadio from "@/components/commons/forms/AppRadio.vue"
import AppDialog from "@/components/commons/AppDialog.vue"
import AppDictionaryDialog from "@/components/commons/forms/AppDictionaryDialog.vue"
import AppTabsHeader from "@/components/commons/AppTabsHeader.vue"
import AppForm from "@/components/commons/forms/AppForm.vue"
import AppFileUpload from "@/components/commons/forms/AppFileUpload.vue"
import AppFillArea from "@/components/commons/AppFillArea.vue"
import AppAdminPanelTabWrapper from "@/components/commons/admin-panel/AppAdminPanelTabWrapper.vue"
import AppFormsValidator from "@/components/commons/forms/AppFormsValidator.vue"
import AppList from "@/components/commons/AppList.vue"

import quasarUserOptions from "@/quasarUserOptions.js"

import { useGlobalStore } from "@/stores/global.js"
import { useMapsStore } from "@/stores/maps.js"
import { useDictionariesStore } from "@/stores/dictionaries.js"
import { useSessionStore } from "@/stores/session.js"

setup.setupAxios()

async function main() {
  const app = createApp(App)

  app.use(pinia)
  // Make sure global config is set before router is initialized so router can use it
  const globalStore = useGlobalStore()
  const mapStore = useMapsStore()
  const dictionariesStore = useDictionariesStore()
  const sessionStore = useSessionStore()

  await globalStore.fetchConfigs()
  await Promise.all([mapStore.fetchConfigs(), dictionariesStore.fetchDictionaries()])

  if (globalStore.globalConfig.ssoSettings.saml.enabled) {
    sessionStore.handleSamlCookie()
  }

  app.use(eventBus)
  app.use(Quasar, quasarUserOptions)
  app.use(router)
  app.use(i18n)
  app.use(PortalVue)

  app.component("AppButton", AppButton)
  app.component("AppCard", AppCard)
  app.component("AppInput", AppInput)
  app.component("AppNumberInput", AppNumberInput)
  app.component("AppCoordinatesInput", AppCoordinatesInput)
  app.component("AppDatePicker", AppDatePicker)
  app.component("AppTable", AppTable)
  app.component("AppTabPanels", AppTabPanels)
  app.component("AppSimpleTable", AppSimpleTable)
  app.component("AppFormRules", AppFormRules)
  app.component("AppCheckbox", AppCheckbox)
  app.component("AppSelect", AppSelect)
  app.component("AppRadio", AppRadio)
  app.component("AppDialog", AppDialog)
  app.component("AppDictionaryDialog", AppDictionaryDialog)
  app.component("AppForm", AppForm)
  app.component("AppTabsHeader", AppTabsHeader)
  app.component("AppFileUpload", AppFileUpload)
  app.component("AppFillArea", AppFillArea)
  app.component("AppAdminPanelTabWrapper", AppAdminPanelTabWrapper)
  app.component("AppFormsValidator", AppFormsValidator)
  app.component("AppList", AppList)

  app.mount("#app")
}

main().catch((error) => {
  console.error("Failed to initialize the app", error)
})
