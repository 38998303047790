export const routeNames = {
  root: "root",
  mainLogin: "mainLogin",
  mainModules: "mainModules",
  mainHelp: "mainHelp",
  mainDocumentation: "mainDocumentation",
  mainAdminPanel: "mainAdminPanel",
  mainChangePassword: "mainChangePassword",
  mainSupervision: "mainSupervision",
  mainStatDashboard: "mainStatDashboard",
  mainStatCharts: "mainStatCharts",
  mainAccount: "mainAccount",
  mainAccountEdit: "mainAccountEdit",
  mainMapTest: "mainMapTest",
  mainUrlViewer: "mainUrlViewer",

  mwParityRoot: "mwParityRoot",
  mwParityMain: "mwParityMain",
  mwParityAdminPanel: "mwParityAdminPanel",

  mwApplicationsRequestsMain: "mwApplicationsRequestsMain",
  mwApplicationsRequestsApplication: "mwApplicationsRequestsApplication",
  mwApplicationsRequestsConsultation: "mwApplicationsRequestsConsultation",
  mwApplicationsRequestsAdminPanel: "mwApplicationsRequestsAdminPanel"
}

export const routes = [
  {
    path: "/",
    name: routeNames.root,
    component: () => import("@/views/main/DefaultRedirectView.vue")
  },
  {
    path: "/main/login/:mode?",
    name: routeNames.mainLogin,
    component: () => import("@/views/main/LoginView.vue")
  },
  {
    path: "/main/modules/:mode?",
    name: routeNames.mainModules,
    component: () => import("@/views/main/ModulesView.vue")
  },
  {
    path: "/main/help-page",
    name: routeNames.mainHelp,
    component: () => import("@/views/main/HelpPageView.vue")
  },
  {
    path: "/main/documentation",
    name: routeNames.mainDocumentation,
    component: () => import("@/views/main/DocumentationView.vue")
  },
  {
    path: "/main/admin-panel/:tab?/:subtab?",
    name: routeNames.mainAdminPanel,
    component: () => import("@/views/main/AdminPanelView.vue")
  },
  {
    path: "/main/help-page",
    name: routeNames.mainHelp,
    component: () => import("@/views/main/HelpPageView.vue")
  },
  {
    path: "/main/passwords-management/:mode/:token?",
    name: routeNames.mainChangePassword,
    component: () => import("@/views/main/ChangePasswordView.vue")
  },
  {
    path: "/main/supervision",
    name: routeNames.mainSupervision,
    component: () => import("@/views/main/SupervisionView.vue")
  },
  {
    path: "/main/stat-dashboard",
    name: routeNames.mainStatDashboard,
    component: () => import("@/views/main/StatDashboardView.vue")
  },
  {
    path: "/main/stat-charts/:module/:year/:month",
    name: routeNames.mainStatCharts,
    component: () => import("@/views/main/StatChartsView.vue")
  },
  {
    path: "/main/account",
    name: routeNames.mainAccount,
    component: () => import("@/views/main/AccountView.vue")
  },
  {
    path: "/main/account/:mode",
    name: routeNames.mainAccountEdit,
    component: () => import("@/views/main/AccountView.vue")
  },
  {
    path: "/main/map-test",
    name: routeNames.mainMapTest,
    component: () => import("@/views/main/MapTestView.vue")
  },
  {
    path: "/main/url-viewer/:alias?",
    name: routeNames.mainUrlViewer,
    component: () => import("@/views/main/UrlViewerView.vue")
  },

  {
    path: "/mw-parity",
    name: routeNames.mwParityRoot,
    redirect() {
      return { name: routeNames.mwParityMain }
    }
  },
  {
    path: "/mw-parity/view",
    name: routeNames.mwParityMain,
    component: () => import("@/views/mw-parity/MainView.vue")
  },
  {
    path: "/mw-parity/admin-panel/:tab?/:subtab?",
    name: routeNames.mwParityAdminPanel,
    component: () => import("@/views/mw-parity/AdminPanelView.vue")
  },

  {
    path: "/mw-applications-requests",
    name: routeNames.mwApplicationsRequestsRoot,
    redirect() {
      return { name: routeNames.mwApplicationsRequestsMain }
    }
  },
  {
    path: "/mw-applications-requests/start-page",
    name: routeNames.mwApplicationsRequestsMain,
    component: () => import("@/views/mw-applications-requests/MainView.vue")
  },
  {
    path: "/mw-applications-requests/application/:id?",
    name: routeNames.mwApplicationsRequestsApplication,
    component: () => import("@/views/mw-applications-requests/ApplicationView.vue")
  },
  {
    path: "/mw-applications-requests/consultation/:id/:tab?/:subtab?",
    name: routeNames.mwApplicationsRequestsConsultation,
    component: () => import("@/views/mw-applications-requests/ConsultationView.vue")
  },
  {
    path: "/mw-applications-requests/admin-panel/:tab?/:subtab?",
    name: routeNames.mwApplicationsRequestsAdminPanel,
    component: () => import("@/views/mw-applications-requests/AdminPanelView.vue")
  }
]
