import { getModulesDisplayInfo } from "@/constants/main/modules.js"

export const getToAndHrefFromModuleInfo = (moduleInfo, settingsMode) => {
  if (!moduleInfo.v3) {
    return {
      href: `/frontend/${moduleInfo.urlPath}${settingsMode ? "/#/admin-panel" : ""}`,
      to: undefined
    }
  }

  return {
    href: undefined,
    to: settingsMode ? moduleInfo.adminPanelRoute : moduleInfo.route
  }
}

export const getModuleInfo = (moduleKey) => getModulesDisplayInfo().find((moduleInfo) => moduleInfo.key === moduleKey)
