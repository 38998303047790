import { hubConnection } from "signalr-no-jquery"

import eventBus from "@/plugins/eventBus.js"
import { getModuleForRoute } from "@/plugins/router.js"

import { useSessionStore } from "@/stores/session.js"

let proxy = null
let connection = null

export const init = () => {
  connection = hubConnection()
  proxy = connection.createHubProxy("notificationHub")

  proxy.on("receiveMessage", (message) => eventBus.$emit("ws-adminNotificationReceive", message))
  proxy.on("receiveForceLogout", (message) => eventBus.$emit("ws-forceLogoutReceive", message))
  proxy.on("receiveNextMaintenance", (data) => eventBus.$emit("ws-receiveNextMaintenance", data))
  proxy.on("notifyUserConnections", (data) => eventBus.$emit("ws-notifyUserConnections", data))

  eventBus.$on("ws-adminNotificationSend", (message) => proxy.invoke("sendMessage", message))
  eventBus.$on("ws-forceLogout", (message) => proxy.invoke("forceLogout", message))
  eventBus.$on("access-token-refreshed", resetConnection)
  eventBus.$on("before-log-out", closeConnection)

  resetConnection()
}

const updateAccessToken = () => {
  const sessionStore = useSessionStore()
  connection.qs = {
    access_token: sessionStore.accessToken,
    module: getModuleForRoute()
  }
}

const resetConnection = () => {
  updateAccessToken()
  closeConnection()
  startConnection()
}

const startConnection = async () => {
  try {
    await connection.start()
  } catch (err) {
    console.error("Error starting SignalR connection:", err)
  }
}

const closeConnection = () => {
  if (connection) {
    connection.stop()
  }
}
