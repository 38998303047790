import { ref } from "vue"
import { defineStore } from "pinia"

import * as settingsApi from "@/api/settingsApi.js"

import * as quasarUtils from "@/services/quasarUtils.js"

import i18n from "@/plugins/i18n.js"

export const useGlobalStore = defineStore("global", () => {
  const globalConfig = ref({})
  const globalSettings = ref({})

  async function fetchConfigs() {
    try {
      const [globalConfigData, globalSettingsData] = await Promise.all([settingsApi.getGlobalConfiguration(), settingsApi.getGlobalSettings()])

      globalConfig.value = globalConfigData
      globalSettings.value = globalSettingsData
    } catch (error) {
      if (error?.response?.status === 500) {
        document.write("Error: Invalid or missing license key, please contact portal administrator")
      }
    }
  }

  const setGlobalSettings = async (settings) => {
    await settingsApi.setGlobalSettings(settings)
    await fetchConfigs()
    quasarUtils.showToast({ message: i18n.global.t("_RSFAP_SHARED_SAVED") })
  }

  const redirectToSsoPortal = () => {
    const redirectPortalUrl = globalConfig.value.ssoSettings.customSso.portalUrl
      ? globalConfig.value.ssoSettings.customSso.portalUrl
      : "http://no-portal-url-specified.example.com"
    const port = window.location.port ? ":" + window.location.port : ""
    const currentOriginAndPath = `${window.location.protocol}//${window.location.hostname}${port}${window.location.pathname}`
    const currentOriginAndPathInBase64 = btoa(currentOriginAndPath)
    const redirectionParameter = currentOriginAndPathInBase64
    const redirectionAddress = `${redirectPortalUrl}?url=${redirectionParameter}`
    window.location.replace(redirectionAddress)
  }

  return { globalConfig, globalSettings, fetchConfigs, setGlobalSettings, redirectToSsoPortal }
})
