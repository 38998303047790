import { routeNames } from "@/constants/routes.js"

import i18n from "@/plugins/i18n.js"

export const getModulesDisplayInfo = () => [
  {
    key: "main",
    title: i18n.global.t("MODULES_GENERAL"),
    adminPanelRoute: {
      name: routeNames.mainAdminPanel
    },
    settingsOnly: true,
    v3: true
  },
  {
    key: "mwApplicationsRequests",
    title: i18n.global.t("MODULES_APPLICATIONS_AND_REQUESTS_MW"),
    description: i18n.global.t("MODULES_APPLICATIONS_AND_REQUESTS_DESCRIPTION_MW"),
    urlPath: "mw-applications-requests",
    requiredRequestSystems: ["MW"],
    requiredRoles: ["REGULATOR", "OWNER", "REQUEST", "VISITOR"],
    v3: true,
    route: {
      name: routeNames.mwApplicationsRequestsMain
    },
    adminPanelRoute: {
      name: routeNames.mwApplicationsRequestsAdminPanel
    }
  },
  {
    key: "mwExisting",
    title: i18n.global.t("MODULES_EXISTING_MICROWAVE_LINKS"),
    description: i18n.global.t("MODULES_EXISTING_MICROWAVE_LINKS_DESCRIPTION"),
    urlPath: "mw-existing"
  },
  {
    key: "mwParity",
    title: i18n.global.t("MODULES_MW_PARITY"),
    description: i18n.global.t("MODULES_MW_PARITY_DESCRIPTION"),
    urlPath: "mw-parity",
    requiredRequestSystems: ["MW"],
    requiredRoles: ["REGULATOR", "OWNER", "REQUEST", "VISITOR"],
    v3: true,
    route: {
      name: routeNames.mwParityMain
    },
    adminPanelRoute: {
      name: routeNames.mwParityAdminPanel
    },
    accessibleByAdmin: true
  },
  {
    key: "mwDeploymentSimulation",
    title: i18n.global.t("MODULES_MW_DEPLOYMENT_SIMULATION"),
    description: i18n.global.t("MODULES_MW_DEPLOYMENT_SIMULATION_DESCRIPTION"),
    urlPath: "mw-deployment-simulation"
  },
  {
    key: "esApplicationsRequests",
    title: i18n.global.t("MODULES_APPLICATIONS_AND_REQUESTS_ES"),
    description: i18n.global.t("MODULES_APPLICATIONS_AND_REQUESTS_DESCRIPTION_ES"),
    urlPath: "es-applications-requests",
    requiredRequestSystems: ["ES"],
    requiredRoles: ["REGULATOR", "OWNER", "REQUEST", "VISITOR"]
  },
  {
    key: "mobApplicationsRequests",
    title: i18n.global.t("MODULES_APPLICATIONS_AND_REQUESTS_MOB"),
    description: i18n.global.t("MODULES_APPLICATIONS_AND_REQUESTS_DESCRIPTION_MOB"),
    urlPath: "mob-applications-requests"
  },
  {
    key: "bctApplicationsRequests",
    title: i18n.global.t("MODULES_APPLICATIONS_AND_REQUESTS_BCT"),
    description: i18n.global.t("MODULES_APPLICATIONS_AND_REQUESTS_DESCRIPTION_BCT"),
    urlPath: "bct-applications-requests"
  },
  {
    key: "esExisting",
    title: i18n.global.t("MODULES_EXISTING_EARTH_STATIONS"),
    description: i18n.global.t("MODULES_EXISTING_EARTH_STATIONS_DESCRIPTION"),
    urlPath: "es-existing"
  },
  {
    key: "mobExisting",
    title: i18n.global.t("MODULES_EXISTING_MOBILE_NETWORK_STATIONS"),
    description: i18n.global.t("MODULES_EXISTING_MOBILE_NETWORK_STATIONS_DESCRIPTION"),
    urlPath: "mob-existing"
  },
  {
    key: "bctExisting",
    title: i18n.global.t("MODULES_EXISTING_BROADCAST_STATIONS"),
    description: i18n.global.t("MODULES_EXISTING_BROADCAST_STATIONS_DESCRIPTION"),
    urlPath: "bct-existing"
  },
  {
    key: "positionsAndSites",
    title: i18n.global.t("MODULES_POSITIONS_AND_SITES"),
    description: i18n.global.t("MODULES_POSITIONS_AND_SITES_DESCRIPTION"),
    urlPath: "positions-and-sites"
  },
  {
    key: "antennas",
    title: i18n.global.t("MODULES_ANTENNAS"),
    description: i18n.global.t("MODULES_ANTENNAS_DESCRIPTION"),
    urlPath: "antennas",
    accessibleByAdmin: true
  },
  {
    key: "trRxEquipment",
    title: i18n.global.t("MODULES_TX_RX_EQUIPMENT_CONFIGURATION"),
    description: i18n.global.t("MODULES_TX_RX_EQUIPMENT_CONFIGURATION_DESCRIPTION"),
    urlPath: "equipments",
    accessibleByAdmin: true
  },
  {
    key: "adminSites",
    title: i18n.global.t("MODULES_ADMIN_SITES"),
    description: i18n.global.t("MODULES_ADMIN_SITES_DESCRIPTION"),
    urlPath: "admin-sites"
  },
  {
    key: "authorizationsAndLicenses",
    title: i18n.global.t("MODULES_AUTHORIZATIONS_AND_LICENSES"),
    description: i18n.global.t("MODULES_AUTHORIZATIONS_AND_LICENSES_DESCRIPTION"),
    urlPath: "authorizations-and-licenses"
  },
  {
    key: "fees",
    title: i18n.global.t("MODULES_FEES"),
    description: i18n.global.t("MODULES_FEES_DESCRIPTION"),
    urlPath: "fees",
    requiredOrigins: ["USERS", "USERS_CNTCT"],
    requiredRoles: ["FEE"]
  },
  {
    key: "comsis",
    title: i18n.global.t("MODULES_COMSIS"),
    description: i18n.global.t("MODULES_COMSIS_DESCRIPTION"),
    urlPath: "comsis",
    requiredRequestSystems: ["COM"],
    requiredRoles: ["REGULATOR", "OWNER", "REQUEST", "VISITOR", "WATCHER"]
  },
  {
    key: "spectrum",
    title: i18n.global.t("MODULES_SPECTRUM"),
    description: i18n.global.t("MODULES_SPECTRUM_DESCRIPTION"),
    urlPath: "spectrum"
  },
  {
    key: "surveys",
    title: i18n.global.t("MODULES_SURVEYS"),
    description: i18n.global.t("MODULES_SURVEYS_DESCRIPTION"),
    urlPath: "surveys",
    requiresSurveyCheck: true
  },
  {
    key: "pmr_2_6",
    title: i18n.global.t("_SHARED_MODULES_PMR_2_6"),
    description: i18n.global.t("MODULES_PMR_2_6_DESCRIPTION"),
    urlPath: "pmr_2_6",
    requiredRequestSystems: ["PMR"]
  },
  {
    key: "otsApplications",
    title: i18n.global.t("MODULES_OTS_APPLICATIONS"),
    description: i18n.global.t("MODULES_OTS_APPLICATIONS_DESCRIPTION"),
    urlPath: "ots-applications",
    requiredRequestSystems: ["TS"]
  },
  {
    key: "joApplications",
    title: i18n.global.t("MODULES_JO_APPLICATIONS"),
    description: i18n.global.t("MODULES_JO_APPLICATIONS_DESCRIPTION"),
    urlPath: "jo-applications",
    requiredRequestSystems: ["TS"]
  },
  {
    key: "privateRadioNetwork",
    title: i18n.global.t("MODULES_PRIVATE_RADIO_NETWORK"),
    description: i18n.global.t("MODULES_PRIVATE_RADIO_NETWORK"),
    urlPath: "private-radio-network",
    requiredRequestSystems: ["PMR"]
  },
  {
    key: "freqExperimentationRequests",
    title: i18n.global.t("MODULES_FREQ_EXPERIMENTATION_REQUESTS"),
    description: i18n.global.t("MODULES_FREQ_EXPERIMENTATION_REQUESTS_DESCRIPTION"),
    urlPath: "freq-experimentation-requests",
    requiredRoles: ["REGULATOR", "REQUEST", "OWNER"]
  }
]

export const getTranslationForModule = (module) => {
  switch (module.id) {
    case "mwApplicationsRequests":
      return i18n.global.t("MODULES_APPLICATIONS_AND_REQUESTS_MW")
    case "mwParity":
      return i18n.global.t("MODULES_MW_PARITY")
    case "mwExisting":
      return i18n.global.t("MODULES_MW_EXISTING")
    case "mwDeploymentSimulation":
      return i18n.global.t("MODULES_MW_DEPLOYMENT_SIMULATION")
    case "esApplicationsRequests":
      return i18n.global.t("MODULES_APPLICATIONS_AND_REQUESTS_ES")
    case "antennas":
      return i18n.global.t("MODULES_ANTENNAS")
    case "trRxEquipment":
      return i18n.global.t("MODULES_TX_RX_EQUIPMENT_CONFIGURATION")
    case "adminSites":
      return i18n.global.t("MODULES_ADMIN_SITES")
    case "freqExperimentationRequests":
      return i18n.global.t("MODULES_FREQ_EXPERIMENTATION_REQUESTS")
    case "fees":
      return i18n.global.t("MODULES_FEES")
    case "comsis":
      return i18n.global.t("MODULES_COMSIS")
    case "surveys":
      return i18n.global.t("MODULES_SURVEYS")
    case "spectrum":
      return i18n.global.t("MODULES_SPECTRUM")
    case "pmr_2_6":
      return i18n.global.t("_SHARED_MODULES_PMR_2_6")
    case "otsApplications":
      return i18n.global.t("MODULES_OTS_APPLICATIONS")
    case "joApplications":
      return i18n.global.t("MODULES_JO_APPLICATIONS")
    case "privateRadioNetwork":
      return i18n.global.t("MODULES_PRIVATE_RADIO_NETWORK")
    default:
      return module.id
  }
}

export const getAvailableLoginPageModules = () => [
  {
    value: "main/#/login/form",
    label: i18n.global.t("ADMIN_PANEL_TAB_LOGIN_PAGE_BUTTONS_MODAL_MODULE_MAIN_LOGIN_FORM"),
    always: true
  },
  {
    value: "pmr_2_6/#/view",
    label: i18n.global.t("_SHARED_MODULES_PMR_2_6"),
    name: "pmr_2_6"
  },
  {
    value: "mw-parity/#/view",
    label: i18n.global.t("MODULES_MW_PARITY"),
    name: "mwParity"
  },
  {
    value: "spectrum/#/view",
    label: i18n.global.t("MODULES_SPECTRUM"),
    name: "spectrum"
  },
  {
    value: "mw-deployment-simulation/#/simulation",
    label: i18n.global.t("MODULES_MW_DEPLOYMENT_SIMULATION"),
    name: "mwDeploymentSimulation"
  }
]

export const redirectable = [
  { id: "modules", name: "modules" },
  { id: "helpPage", name: "helpPage" },
  { id: "documentation", name: "documentation" }
]

export const redirectableModules = [
  { id: "mwApplicationsRequests", name: "mwApplicationsRequests" },
  { id: "mwParity", name: "mwParity" },
  { id: "mwExisting", name: "mwExisting" },
  { id: "mwDeploymentSimulation", name: "mwDeploymentSimulation" },
  { id: "esApplicationsRequests", name: "esApplicationsRequests" },
  { id: "antennas", name: "antennas" },
  { id: "trRxEquipment", name: "trRxEquipment" },
  { id: "adminSites", name: "adminSites" },
  { id: "fees", name: "fees" },
  { id: "comsis", name: "comsis" },
  { id: "surveys", name: "surveys" },
  { id: "pmr_2_6", name: "pmr_2_6" },
  { id: "otsApplications", name: "otsApplications" },
  { id: "joApplications", name: "joApplications" },
  { id: "privateRadioNetwork", name: "privateRadioNetwork" }
]

export const allModules = [
  { id: "mwApplicationsRequests", name: "mwApplicationsRequests" },
  { id: "mwParity", name: "mwParity" },
  { id: "mwExisting", name: "mwExisting" },
  { id: "mwDeploymentSimulation", name: "mwDeploymentSimulation" },
  { id: "esApplicationsRequests", name: "esApplicationsRequests" },
  { id: "antennas", name: "antennas" },
  { id: "trRxEquipment", name: "trRxEquipment" },
  { id: "freqExperimentationRequests", name: "freqExperimentationRequests" },
  { id: "adminSites", name: "adminSites" },
  { id: "fees", name: "fees" },
  { id: "comsis", name: "comsis" },
  { id: "surveys", name: "surveys" },
  { id: "spectrum", name: "spectrum" },
  { id: "pmr_2_6", name: "pmr_2_6" },
  { id: "otsApplications", name: "otsApplications" },
  { id: "joApplications", name: "joApplications" },
  { id: "privateRadioNetwork", name: "privateRadioNetwork" }
]

export const redirectableForAdmin = [
  { id: "modules", name: "modules" },
  { id: "helpPage", name: "helpPage" },
  { id: "modulesSettings", name: "modules", params: { mode: "settings" } },
  { id: "documentation", name: "documentation" }
]

export const getModuleEvents = () => [
  {
    name: "comsis",
    events: [
      {
        name: "stationOpened",
        label: i18n.global.t("STATS_COMSIS_STATION_OPENED"),
        params: [
          {
            name: "stationManager",
            label: i18n.global.t("STATS_COMSIS_STATION_MANAGER")
          },
          {
            name: "stationAnfr",
            label: i18n.global.t("STATS_COMSIS_STATION_ANFR")
          },
          {
            name: "stationName",
            label: i18n.global.t("STATS_COMSIS_STATION_NAME")
          }
        ]
      },
      {
        name: "reportGenerated",
        label: i18n.global.t("STATS_COMSIS_REPORT_GENERATED"),
        params: [
          {
            name: "reportType",
            label: i18n.global.t("STATS_COMSIS_REPORT_TYPE")
          },
          {
            name: "reportName",
            label: i18n.global.t("STATS_COMSIS_REPORT_NAME")
          },
          {
            name: "stationAnfr",
            label: i18n.global.t("STATS_COMSIS_STATION_ANFR")
          },
          {
            name: "stationName",
            label: i18n.global.t("STATS_COMSIS_STATION_NAME")
          }
        ]
      },
      {
        name: "reportPreviewed",
        label: i18n.global.t("STATS_COMSIS_REPORT_PREVIEWED"),
        params: [
          {
            name: "reportName",
            label: i18n.global.t("STATS_COMSIS_REPORT_NAME")
          },
          {
            name: "stationAnfr",
            label: i18n.global.t("STATS_COMSIS_STATION_ANFR")
          },
          {
            name: "stationName",
            label: i18n.global.t("STATS_COMSIS_STATION_NAME")
          }
        ]
      },
      {
        name: "showAllUsed",
        label: i18n.global.t("STATS_COMSIS_SHOW_ALL_USED"),
        params: []
      }
    ]
  }
]
